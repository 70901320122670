// Colors

// The color palette
$palette: (
  text: (
    header: #515751,
    base: #515751,
    highlight: #a93346,
    invert: #fdfffc,
  ),
  divider: (
    1: #f2f2f2,
    2: #515751,
    3: #e8b714,
    4: #a93346,
  ),
  bg: (
    1: #fdfffc,
    2: #f2f2f2,
    3: #a93346,
  ),
  light: (
    0: #fdfffc,
    1: #eceded,
    2: #9ca9b3,
    3: #717d86,
  ),
  dark: (
    1: #151719,
    2: #25282c,
    3: #33363a,
  ),
  primary: (
    0: #a93346,
    1: #6163ff,
    2: #acadff,
    3: #5658dd,
    4: #e9e9ff,
  ),
  secondary: (
    1: #24e5af,
    2: #73efcc,
    3: #1cb68b,
    4: #d2f9ee,
  ),
  alert: (
    error: #ff6171,
    warning: #ffa173,
    success: #24e5af,
  ),
);

// Base typography: color
$color: (
  heading: get-color(text, header),
  base: get-color(text, base),
  high-contrast: get-color(light, 1),
  mid-contrast: get-color(light, 2),
  low-contrast: get-color(light, 3),
  primary: get-color(primary, 0),
  secondary: get-color(secondary, 1),
  error: get-color(alert, error),
  warning: get-color(alert, warning),
  success: get-color(alert, success),
  // ↓ Inverted colors
  heading-inverse: get-color(dark, 1),
  base-inverse: get-color(light, 3),
  high-contrast-inverse: get-color(dark, 1),
  mid-contrast-inverse: get-color(dark, 2),
  low-contrast-inverse: get-color(light, 3),
);

// Borders and dividers: color
$border--color: (
  divider: get-color(divider, 1),
  divider-inverse: get-color(divider, 1),
  menu-divider: lighten(get-color(divider, 2), 50%),
);

// Icons: fill color
$icon--color: (
  // hamburger icon
  hamburger: get-color(text, base),
  // accordion icon
  accordion: get-color(primary, 1),
  // social icons
  social: get-color(primary, 1),
  // social icons (:hover)
  social-hover: get-color(primary, 2),
  // modal icon
  modal: get-color(light, 3),
  // modal icon (:hover)
  modal-hover: get-color(light, 2),
  // news - read more link arrow
  news-more: get-color(primary, 2),
  // ↓ Inverted colors
  // hamburger icon
  hamburger-inverse: get-color(dark, 2),
  // accordion icon
  accordion-inverse: get-color(primary, 1),
  // social icons
  social-inverse: null,
  // social icons (:hover)
  social-hover-inverse: null
);

// Misc elements: background color
$bg--color: (
  body: get-color(light, 0),
  // body shadow when a boxed layout is used (set to null if don't want a shadow)
  body-shadow: 0 20px 48px rgba(get-color(dark, 1), 0.8),
  // outer bg color when a boxed layout is used
  body-outer: lighten(get-color(dark, 1), 2%),
  // .has-bg-color helper class
  bg-color: lighten(get-color(light, 1), 9%),
  // .has-shadow helper class
  shadow: 0 24px 64px rgba(get-color(dark, 1), 0.64),
  code: darken(get-color(dark, 1), 3%),
  code-inverse: darken(get-color(dark, 1), 3%),
  check-checked-inverse: darken(get-color(dark, 1), 3%),
);
